













































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { INewsletter, INewsletterSearchParams } from '@/interfaces';
import { dispatchGetNewslettersList, dispatchDeleteNewsletter } from '@/store/main/accessors';
import { readHasAdminAccess } from '@/store/main/accessors';
import { format, parseISO } from 'date-fns';
import ConfirmDialog from '@/components/ConfirmDialog.vue';

@Component({
  components: {
    ConfirmDialog,
  },
})
export default class Newsletters extends Vue {
  public newsletterId: string | null = null;
  public search: string = '';
  public loading: boolean = false;
  public newsletters: INewsletter[] = [];
  public totalNewsletters: number = 0;

  public range = [];
  public sender: string = '';
  public receiver: string = '';

  public pagination: { sortBy; descending; page; rowsPerPage; footerProps } = {
    sortBy: ['id'],
    descending: [false],
    page: 1,
    rowsPerPage: 20,
    footerProps: { 'items-per-page-options': [10, 20, 50, 100] },
  };

  public $refs!: {
    confirm: InstanceType<typeof ConfirmDialog>;
  };

  public async updateNewsletters(clearPagination?: boolean) {
    if (clearPagination) {
      this.pagination.page = 1;
    }
    this.loading = true;
    const data = await this.getDataFromApi();
    this.totalNewsletters = data.total;
    this.newsletters = data.items;
    this.loading = false;
  }

  @Watch('pagination', { deep: true })
  public async onPaginationChange(val) {
    await this.updateNewsletters();
  }

  public get headers() {
    const headers = [
      {
        text: this.$vuetify.lang.t('$vuetify.name'),
        value: 'name',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.service'),
        value: 'service',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.sender'),
        value: 'sender',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.scheduledDate'),
        value: 'scheduled_dt',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.isTZConsidered'),
        value: 'is_tz_considered',
        align: 'left',
      },
      {
        text: this.$vuetify.lang.t('$vuetify.actions'),
        value: 'id',
      },
    ];

    return headers;
  }

  public get hasAdminAccess() {
    return readHasAdminAccess(this.$store);
  }

  public async mounted() {
    await this.updateNewsletters();
  }

  public async getDataFromApi() {
    const { sortBy, descending, page, rowsPerPage } = this.pagination;
    const skip = (page - 1) * rowsPerPage;
    const params: INewsletterSearchParams = {
      skip,
      limit: rowsPerPage,
      orderBy: sortBy[0],
      descending: descending[0],
      newsletterId: this.newsletterId,
    };
    return await dispatchGetNewslettersList(this.$store, params);
  }

  public async deleteNewsletter(id: number) {
    if (await this.$refs.confirm.open(this.$vuetify.lang.t('$vuetify.deleteEntryConfirmation'))) {
      await dispatchDeleteNewsletter(this.$store, id);
      await this.updateNewsletters();
    }
  }

  public getDateFormat(dtStr: string) {
    return format(parseISO(dtStr), `yyyy-MM-dd kk:mm`);
  }
}
